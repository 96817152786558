import React from 'react'

const Line = () => {
  return (
    <div className='d-flex justify-content-center align-items-center py-5'>
        <hr style={{backgroundColor:' #92C5DA',width:'300px',height:'2px'}}/>
    </div>
  )
}

export default Line