import { useEffect } from 'react'
import Aos from 'aos'
import Line from "../shared/layout/Line"


const Newsletter = () => {
  useEffect(
    ()=>{
      Aos.init({duration:1000})
    }
  ,[])
  return (
    <section className='newsletter-wrapper-css pt-5'>
        <div className='news-lettre-css container' data-aos="fade-right">
            <h2 className='mb-4 text-white text-center'>Join Our Newsletter</h2>
            <form className='d-flex flex-md-row flex-column justify-content-center align-items-center text-white text-center'>
                <input className='py-3 px-4 me-md-4 me-0 border-0' type="email" placeholder='email address'/>
                <button className='py-3 px-4 border-0 text-white mt-md-0 mt-4 text-uppercase fw-normal'>subscribe</button>
            </form>
            <Line/>
        </div>
    </section>
  )
}

export default Newsletter