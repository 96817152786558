import { Helmet } from "react-helmet";

import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import img from "../assets/image/blogg3.jpg";
const Blog3 = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Si vous voulez un look plus dramatique pour une soirée, Comment appliquer le fard à paupières meme si vous êtes débutante en 5 étapes et cela ne vous prendra que quelques minutes."
        />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/blog/Comment-appliquer-le-fard-à-paupières-pour-les-débutants-en-5-étapes"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Comment appliquer le fard à paupières pour les débutants en 5 étapes - Prettywomen blog"
        />
        <meta
          property="og:description"
          content="Si vous voulez un look plus dramatique pour une soirée, Comment appliquer le fard à paupières meme si vous êtes débutante en 5 étapes et cela ne vous prendra que quelques minutes."
        />
        <meta property="og:image" content="" />
        <title>
          Comment appliquer le fard à paupières pour les débutants en 5 étapes -
          Prettywomen blog
        </title>
      </Helmet>
      <Announcement />
      <Header />
      <main className="blog-wrapper-css">
        <div className="container">
          <article className="text-center py-5">
            <h1 className="blog-heading">pretty women blog</h1>
          </article>
          <article className="main-blog-css">
            <img
              className="img-fluid"
              src={img}
              alt="pretty women Comment appliquer le fard à paupières pour les débutants en 5 étapes blog "
            />
            <article className="mt-4">
              <div className="text-muted text-uppercase">
                <time>15th July 2020, </time>
                <abbr>By Amy Burton</abbr>
              </div>
              <h2 className="mt-4 mb-3">
                Comment appliquer le fard à paupières (pour les débutants) en 5
                étapes
              </h2>
              <p className="mt-2 mb-0 text-light text-uppercase">
                Le fard à paupières peut sembler être une partie délicate d'un
                maquillage. Après tout, il y a tellement de couleurs
                différentes, sans parler des pinceaux. Heureusement, même si
                vous commencez tout juste à vous maquiller, il peut être facile
                de choisir et d'appliquer un fard à paupières. Pour un
                maquillage de tous les jours que vous pouvez porter au travail
                ou déjeuner avec des amis, essayez un look de fard à paupières
                naturel. Si vous voulez un look plus dramatique pour une soirée,
                optez pour un simple smokey eye qui ne prendra que quelques
                minutes à créer !
              </p>
              <article className="my-4">
                <h3 className="mb-3">
                  Créer un look de fard à paupières naturel
                </h3>
                <p className="text-light text-uppercase">
                  Choisissez une couleur neutre et une teinte plus foncée. Tout
                  ce dont vous aurez besoin pour créer un look de fard à
                  paupières simple et naturel, ce sont 2 couleurs de fard à
                  paupières : une base similaire à la couleur de votre peau et
                  une autre quelques nuances plus foncées. Bien que vous
                  puissiez choisir les couleurs que vous aimez, une teinte
                  neutre qui complète votre teint de peau aura l'air la plus
                  naturelle.  ⦁ Si vous avez la peau claire, utilisez une teinte
                  de base juste un peu plus foncée que votre carnation. Si votre
                  peau est plus foncée, optez pour une couleur un peu plus
                  claire que votre peau, pour vous assurer qu'elle ressort. ⦁
                  Par exemple, si votre peau est claire, vous pouvez choisir un
                  fard à paupières champagne ou beige clair comme couleur de
                  base et un fard à paupières marron clair ou taupe comme
                  deuxième teinte. Si vous avez un teint plus foncé, optez pour
                  une teinte caramel comme base et accentuez-la avec une teinte
                  cuivrée foncée.
                </p>
              </article>
              <article className="my-4">
                <h3 className="mb-3">
                  Passez légèrement votre pinceau dans la couleur de base.
                </h3>
                <p className="text-light text-uppercase">
                  Tenez votre pinceau à paupières entre le pouce, l'index et le
                  majeur de votre main dominante, puis brossez légèrement les
                  pointes des poils sur la couleur de base pour ramasser une
                  partie du pigment sur le pinceau. Il est préférable de
                  commencer avec moins de produit et d'en ajouter plus, alors
                  n'appuyez pas trop fort sur le pinceau dans l'ombre. La
                  plupart des palettes de fards à paupières sont livrées avec un
                  applicateur à pinceau éponge, ou vous pouvez utiliser un
                  pinceau à fard à paupières séparé avec des poils si vous en
                  avez un. Comme il s'agit d'un look simple, vous obtiendrez un
                  effet similaire quel que soit le type de pinceau que vous
                  utilisez. Si vous n'avez pas de pinceau, utilisez plutôt un
                  coton-tige ou un applicateur éponge.
                </p>
              </article>
              <article className="mt-4">
                <h3 className="mb-3">
                  Retirez l'excédent de poudre en tapotant le pinceau.
                </h3>
                <p className="text-light text-uppercase mb-0">
                  Parfois, de petites taches de poudre de fard à paupières
                  peuvent se coincer à la surface de votre pinceau. Cela peut
                  conduire à une application inégale. Pour éviter ce problème,
                  tapotez doucement le côté de votre pinceau contre la palette
                  de fards à paupières, votre plan de travail ou même le dos de
                  votre autre main. Faites-le que vous utilisiez une brosse
                  éponge ou une brosse à poils.
                </p>
              </article>
            </article>
          </article>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Blog3;
