import React from 'react'
import logo from '../assets/image/logo.svg'
const Lazyloading = () => {
  return (
    <div className='vh-100 d-flex justify-content-center align-items-center lazy-loading-css'>
        <div className='d-flex justify-content-center align-items-center'>
            <img className='img-fluid' src={logo} alt='pretty women logo'/>
            
        </div>
    </div>
  )
}

export default Lazyloading