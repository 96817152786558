import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";

const Announcement = () => {
  return (
    <div className="d-lg-block d-none bg-black announcement-css">
      <div className="container">
        <div className="row py-2">
          <nav className="announcement-bar-text-css d-flex align-items-center justify-content-between col-lg-9 col-12">
            <a
              title="pretty women address"
              className="text-decoration-none d-flex align-items-center text-muted"
              href="https://goo.gl/maps/vHfZQrkENCoyqag87"
            >
              <FontAwesomeIcon icon={faLocationDot} className="me-2" />
              <address className="mb-0">
                Nassim, sidi maarouf, rue7 N°178 rez de chaussée, Casablanca
              </address>
            </a>
            <a title="pretty women telephone" className="text-decoration-none text-muted" href="tel:05520265447">
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              +212 52026-5447
            </a>
            <a title="pretty women email" className="text-decoration-none text-muted" href="mailto:contact@prettywomen.ma">
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              contact@prettywomen.ma
            </a>
          </nav>
          <nav className="announcement-bar-text-css col-lg-3 col-6 d-lg-flex d-none align-items-center justify-content-end">
            <a title="pretty women instagram" className="text-decoration-none text-muted me-4" href="https://www.instagram.com/prettywomen.ma" target={'_blank'} rel={'noreferrer'}>
              Follow us:
            </a>
            <nav
              className="text-muted text-decoration-none"
              href="www"
              title="Blog and News"
            >
              <a title="pretty women instagram" href="https://www.instagram.com/prettywomen.ma" className="text-muted me-3 fs-6" target={'_blank'} rel={'noreferrer'}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a title="pretty women facebook" href="https://web.facebook.com/prettywomen.ma" className="text-muted me-3 fs-6" target={'_blank'} rel={'noreferrer'}>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a title="pretty women youtube" href="https://www.youtube.com/channel/UCIncLXg4IuHNfAaw0gXvjcQ" className="text-muted me-3 fs-6" target={'_blank'} rel={'noreferrer'}>
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a title="pretty women tiktok" href="https://www.tiktok.com/@pretty.womencasa" className="text-muted me-3 fs-6" target={'_blank'} rel={'noreferrer'}>
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </nav>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
