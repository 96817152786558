import { Helmet } from "react-helmet";

import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import img from "../assets/image/blog2.jpg";
const Blog2 = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Voici quelques conseils pour prendre soin de soi avant d’aller dormir, il y a cependant quelques petites erreurs que vous faites peut-être et qui pourraient vous coûter cher, nous avons les solutions pour les éviter."
        />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/blog/prendre-soin-de-soi-avant-d’aller-dormir"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Prendre soin de soi avant d’aller dormir" />
        <meta
          property="og:description"
          content="Voici quelques conseils pour prendre soin de soi avant d’aller dormir, il y a cependant quelques petites erreurs que vous faites peut-être et qui pourraient vous coûter cher, nous avons les solutions pour les éviter."
        />
        <meta property="og:image" content="" />
        <title>Prendre soin de soi avant d’aller dormir - Pretty women</title>
      </Helmet>
      <Announcement />
      <Header />
      <main className="blog-wrapper-css">
        <div className="container">
          <article className="text-center py-5">
            <h1 className="blog-heading">pretty women blog</h1>
          </article>
          <article className="main-blog-css">
            <img
              className="img-fluid"
              src={img}
              alt="pretty women Voici quelques conseils pour prendre soin de soi avant d’aller dormir blog"
            />
            <article className="mt-4">
              <div className="text-muted text-uppercase">
                <time>15th July 2020, </time>
                <abbr>By Amy Burton</abbr>
              </div>
              <h2 className="mt-4 mb-3">
                Prendre soin de soi avant d’aller dormir
              </h2>
              <p className="mt-2 mb-0 text-light text-uppercase">
                C’est vrai que le soir, on rêve toutes de se glisser dans ses
                draps avant de dormir, il y a cependant quelques petites erreurs
                que vous faites peut-être et qui pourraient vous coûter cher. On
                les liste pour vous.
              </p>
              <article className="my-4">
                <h3 className="mb-3">
                  Faire l’impasse sur le démaquillage et le nettoyage du visage
                </h3>
                <p className="text-light text-uppercase">
                  Se coucher sans enlever les traces de maquillage est une
                  bêtise, notre peau a besoin de respirer et c’est la nuit
                  qu’elle se régénère le mieux. On se démaquille et on enlève
                  toute trace de pollution et d’impureté, on évite les
                  imperfections et le vieillissement cutané. On choisit le
                  produit qu’on préfère, eau, savon, eau micellaire, lait
                  démaquillant, baume, et on prend soin de son visage.
                </p>
              </article>
              <article className="my-4">
                <h3 className="mb-3">Ne pas se laver les dents</h3>
                <p className="text-light text-uppercase">
                  En plus d’avoir mauvaise haleine, c’est des caries que vous
                  pourriez bien récolter. L’émail de nos dents est protégée par
                  notre salive, or, nous en produisons moins la nuit, si on veut
                  préserver la qualité de l’émail de ses dents, on passe un coup
                  de brosse à dents avec du dentifrice dessus avant de filer au
                  lit.
                </p>
              </article>
              <article className="mt-4">
                <h3 className="mb-3">Ne pas s’attacher les cheveux</h3>
                <p className="text-light text-uppercase mb-0">
                  S’attacher les cheveux leur permet de se salir moins vite,
                  c’est également la meilleure manière d’éviter les noeuds !
                  Lorsque ces derniers sont noués ou tressés, ils sont moins
                  sujets aux frottements, les pointes s’abimeront moins vite.
                </p>
              </article>
            </article>
          </article>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Blog2;
