import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import { Link } from "react-router-dom";
const Error = () => {
  return (
    <>
      <Header />
        <main className="vh-100 d-flex flex-column justify-content-center align-items-center text-white error-page-css">
            <h1>Not found</h1>
            <h2>Error 404</h2>
            <Link to="/" className="text-white text-decoration-none py-2 px-4 rounded mt-4">Go back</Link>
        </main>
      <Footer />
    </>
  );
};

export default Error;
