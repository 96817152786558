import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import {HashLink} from 'react-router-hash-link'
import Line from "./Line";
const Footer = () => {
  return (
    <footer className="footer-wrapper-css">
      <div className="container">
        <div className="row gap-lg-0 gap-5">
          <article className="footer-col-css col-lg-3">
            <h6 className="text-white mb-4">Pretty women </h6>
            <p className="text-muted">
            Désormais chez Pretty Women vous confiez votre beauté à des experts, nous vous offrons une large sélection de traitements et de soins pour une esthétique de pointe. Des techniques esthétiques reconnues qui ont prouvé leur efficacité selon les avis de nos clients satisfaits qui sont la clé de notre réussite.
            </p>
            <button className="mt-2 bg-transparent border-0 text-white fw-semibold position-relative">
              <abbr className="text-uppercase fw-normal">Book online</abbr>
              <span></span>
            </button>
          </article>
          <nav className="d-flex flex-column footer-col-css col-lg-3">
            <h6 className="text-white mb-4">Links</h6>
            <HashLink
              className="text-muted text-decoration-none mb-3"
              to="/#acceuil"
              title="Acceuil"
            >
              Acceuil
            </HashLink>
            <HashLink
              className="text-muted text-decoration-none mb-3"
              to="/#services"
              title="Services"
            >
              Services
            </HashLink>
            <HashLink
              className="text-muted text-decoration-none mb-3"
              to="/#packages"
              title="Packages"
            >
              Packages
            </HashLink>
            <HashLink
              className="text-muted text-decoration-none mb-3"
              to="/#horaires-de-travail"
              title="Horaires de travial"
            >
              Horaires de travail
            </HashLink>
            <HashLink
              className="text-muted text-decoration-none mb-3"
              to="/#about"
              title="About"
            >
              About
            </HashLink>
            <HashLink
              className="text-muted text-decoration-none mb-3"
              to="/#blog"
              title="Blog"
            >
              Blog
            </HashLink>
          </nav>
          <nav className="d-flex flex-column footer-col-css col-lg-3">
            <h6 className="text-white mb-4">Contactez nous</h6>
            <a
              className="text-muted text-decoration-none mb-3"
              href="https://goo.gl/maps/vHfZQrkENCoyqag87"
              title="Nassim, sidi maarouf, rue7 N°178 rez de chaussée, Casablanca"
            >
              <address className="mb-0">
              Nassim, sidi maarouf, rue7 N°178 rez de chaussée, Casablanca
              </address>
            </a>
            <a
              className="text-muted text-decoration-none mb-3"
              href="mailto:contact@prettywomen.ma"
              title="pretty women email"
            >
              contact@prettywomen.ma
            </a>
            <a
              className="text-muted text-decoration-none mb-3"
              href="tel:0520265447"
              title="pretty women telephone"
            >
              Phone: +212 52026-5447
            </a>
            <nav
              className="text-muted text-decoration-none mb-3"
              href="www"
              title="Blog and News"
            >
              <a
                href="https://www.instagram.com/prettywomen.ma"
                title="pretty women instagram"
                className="text-muted me-3 fs-6"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://web.facebook.com/prettywomen.ma"
                title="pretty women facebook"
                className="text-muted me-3 fs-6"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCIncLXg4IuHNfAaw0gXvjcQ"
                title="pretty women youtube"
                className="text-muted me-3 fs-6"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                href="https://www.tiktok.com/@pretty.womencasa"
                title="pretty women tiktok"
                className="text-muted me-3 fs-6"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </nav>
          </nav>
          <nav className="d-flex flex-column footer-col-css col-lg-3">
            <h6 className="text-white mb-4">horaires de travail</h6>
            <div className="d-flex justify-content-between text-muted">
              <span>Mardi - Mercredi</span>
              <time>10:00 - 20:00 hrs</time>
            </div>
            <div className="d-flex justify-content-between mt-3 text-muted">
              <span>Mercredi - Jeudi</span>
              <time>10:00 - 20:00 hrs</time>
            </div>
            <div className="d-flex justify-content-between mt-3 text-muted">
              <span>Jeudi - Vendredi</span>
              <time>10:00 - 20:00 hrs</time>
            </div>
            <div className="d-flex justify-content-between mt-3 text-muted">
              <span>Vendredi - Samedi</span>
              <time>10:00 - 20:00 hrs</time>
            </div>
            <div className="d-flex justify-content-between mt-3 text-muted">
              <span>Samedi - Dimanche</span>
              <time>10:00 - 20:00 hrs</time>
            </div>
          </nav>
        </div>
      </div>
      <Line />
      <div className="d-flex justify-content-center align-items-center pb-5">
        <small className="text-center text-muted">
          &copy; Made with love by{" "}
          <a
            className="text-decoration-none text-muted text-capitalize"
            title="Scaling media"
            href="https://scalingmedia.ma"
            target={"_blank"}
            rel={"noreferrer"}
          >
            Scaling Media
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
