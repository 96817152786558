import { Helmet } from "react-helmet";
import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import img from "../assets/image/blogimage1.jpg";
const Blog = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Voici comment choisir le parfait mascara pour femmes"
        />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/blog/quel-mascara-pour-moi"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Quel mascara pour moi ?" />
        <meta
          property="og:description"
          content="Voici comment choisir le parfait mascara pour femmes"
        />
        <meta property="og:image" content="" />
        <title>Quel mascara pour moi ? - Pretty women blog</title>
      </Helmet>
      <Announcement />
      <Header />
      <main className="blog-wrapper-css">
        <div className="container">
          <article className="text-center py-5">
            <h1 className="blog-heading">pretty women blog</h1>
          </article>
          <article className="main-blog-css">
            <img
              className="img-fluid"
              src={img}
              alt="pretty women quel mascara pour moi blog "
            />
            <article className="mt-4">
              <div className="text-muted text-uppercase">
                <time>15th July 2020, </time>
                <abbr>By Amy Burton</abbr>
              </div>
              <h2 className="mt-4 mb-3">Quel mascara pour moi ?</h2>
              <p className="mt-2 mb-0 text-light text-uppercase">
                Si vous aimez vous maquiller, vous avez forcément un joli
                mascara dans votre trousse de toilette. Pour un maquillage
                réussi, celui-ci doit s’adapter à votre visage et à vos yeux,
                encore faut-il savoir ce qui nous va ! On vous dévoile nos
                conseils pour choisir le mascara qui matchera avec vos cils !
              </p>
              <article className="my-4">
                <h3 className="mb-3">
                  Pour les cils fins : on mise sur le volume
                </h3>
                <p className="text-light text-uppercase">
                  Le mascara volumisant est votre meilleur allié ! L’embout de
                  ce type de mascara est épais et comtient de nombreux poils,
                  c’est grâce à cet embout que vous gagnerez du volume. Vous
                  pouvez aussi adopter un mascara à fibre qui dépose des petites
                  fibres végétales sur chacun de vos cils pour les épaissir. À
                  vous les yeux de biche !
                </p>
              </article>
              <article className="my-4">
                <h3 className="mb-3">
                  Pour les cils droits : on mise sur un produit recourbant
                </h3>
                <p className="text-light text-uppercase">
                  Ce qu’on appelle cils droits, ce sont des cils un peu
                  invisibles, ils peuvent être longs mais on ne les voit pas.
                  Une erreur de mascara ne les mettra certainement pas en valeur
                  ! C’est un mascara recourbant qu’il vous faut ! Sa brosse
                  incurvée s’inspire du recourbe-cils, un autre accessoire bien
                  pratique si on aime le volume ! Ce type de brosse démêle,
                  étoffe et recourbe les cils, on se tourne vers une brosse
                  plutôt large pour pouvoir appliquer le mascara sur tous les
                  cils en même temps. Votre regard est allongé !
                </p>
              </article>
              <article className="mt-4">
                <h3 className="mb-3">
                  Pour les cils courts : on mise sur un mascara allongeant
                </h3>
                <p className="text-light text-uppercase mb-0">
                  Pour allonger vos cils, misez sur une brosse fine avec des
                  larges picots. On choisit un mascara qui contient des
                  microfibres, ces dernières ont la particularité de créer
                  l’illusion en allongeant les cils. Le mascara riche en
                  silicones est intéressant, il texturise les cils et leur
                  apporte de la longueur.
                </p>
              </article>
            </article>
          </article>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Blog;
